import BaseCheckbox from 'v-forms/src/BaseCheckbox';
import BaseRadio from 'v-forms/src/BaseRadio';
import DropzoneFileUpload from 'v-forms/src/DropzoneFileUpload';
import FileInput from 'v-forms/src/FileInput';
import HtmlEditor from 'v-forms/src/HtmlEditor';
import IconCheckbox from 'v-forms/src/IconCheckbox';
import TagsInput from 'v-forms/src/TagsInput';
import ValidatedInput from 'v-forms/src/ValidatedInput';
import ValidatedSelect from 'v-forms/src/ValidatedSelect';
import ValidatedVueSelect from 'v-forms/src/ValidatedVueSelect';

import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import { configure, extend, ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    install: function (Vue) {
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(DropzoneFileUpload.name, DropzoneFileUpload);
        Vue.component(FileInput.name, FileInput);
        Vue.component(HtmlEditor.name, HtmlEditor);
        Vue.component(IconCheckbox.name, IconCheckbox);
        Vue.component(TagsInput.name, TagsInput);
        Vue.component(ValidatedInput.name, ValidatedInput);
        Vue.component(ValidatedSelect.name, ValidatedSelect);
        Vue.component(ValidatedVueSelect.name, ValidatedVueSelect);

        Vue.component('validation-provider', ValidationProvider);
        Vue.component('validation-observer', ValidationObserver);

        Object.keys(rules).forEach(rule => {
            extend(rule, {
                ...rules[rule], // copies rule configuration
                message: messages[rule] // assign message
            });
        });

        configure({
            classes: {
                valid: 'is-valid',
                invalid: 'is-invalid',
                dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
            }
        });
    }
};
