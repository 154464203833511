import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import users from './users';
import auth from './auth';
import NProgress from 'nprogress';

Vue.use(VueRouter);

const routes = [
    appRoutes,
    users,
    auth
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
