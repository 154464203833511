import guards from './guards';
const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/DashboardLayout');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/dashboard/',
    name: 'DashboardLayout',
    redirect: '/dashboard/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/dashboard/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/app/admin/category/',
            name: 'category',
            component: () => import('../views/admin/category/ListCategory')
        },
        {
            path: '/app/admin/product/',
            name: 'product',
            component: () => import('../views/admin/product/ListProduct')
        },
        {
            path: '/app/admin/back-ground/image/',
            name: 'product',
            component: () => import('../views/admin/background-image/ListImage')
        }
    ]
};
