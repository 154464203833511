const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/';
}

export default {
    baseUrl,
    auth: {
        login: baseUrl + 'user/auth/login/',
        status: baseUrl + 'user/auth/status/',
        logout: baseUrl + 'user/auth/logout/'
    },
    logo: baseUrl + 'shop/get-logo/image/',
    user: {
        ProductList: baseUrl + 'shop/list/product/'
    },
    admin: {
        category: {
            addEdit: baseUrl + 'shop/category/add-edit/',
            list: baseUrl + 'shop/category/list/',
            delete: baseUrl + 'shop/category/delete/',
            options: baseUrl + 'shop/category/options/',
            ParentOptions: baseUrl + 'shop/category/parent/options/',
            disableEnable: baseUrl + 'shop/category/disable-enable/'
        },
        productMaster: {
            addEdit: baseUrl + 'shop/product-master/add-edit/',
            list: baseUrl + 'shop/product-master/list/',
            delete: baseUrl + 'shop/product-master/delete/',
            disableEnable: baseUrl + 'shop/product-master/disable-enable/'
        },
        backgroundImage: {
            addEdit: baseUrl + 'shop/listing-background/image/add-edit/',
            list: baseUrl + 'shop/listing-background/image/list/',
            delete: baseUrl + 'shop/listing-background/image/delete/',
            ChangeStatus: baseUrl + 'shop/listing-background/image/change-status//'
        }
    }
};
