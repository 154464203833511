import Actions from 'v-tables/src/Actions';
// import BootstrapTable from 'v-tables/src/BootstrapTable';
import CardDataList from 'v-tables/src/CardDataList';
import DataList from 'v-tables/src/DataList';
import SimpleVueTable from 'v-tables/src/SimpleVueTable';
import VueTable from 'v-tables/src/VueTable';
import VueTablePaginationBootstrap from 'v-tables/src/VueTablePaginationBootstrap';

export default {
    install: function (Vue) {
        Vue.component(Actions.name, Actions);
        // Vue.component(BootstrapTable.name, BootstrapTable);
        Vue.component(CardDataList.name, CardDataList);
        Vue.component(DataList.name, DataList);
        Vue.component(SimpleVueTable.name, SimpleVueTable);
        Vue.component(VueTable.name, VueTable);
        Vue.component(VueTablePaginationBootstrap.name, VueTablePaginationBootstrap);
    }
};
